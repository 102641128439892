import styled from 'styled-components';
import Container from 'components/shared/Container';
import { breakpoints } from 'variables';

export const StyledContainer = styled(Container)`
   margin-top: 30px;
   flex-wrap: wrap;

   ${breakpoints.lg`
      margin-top: 100px;
   `}
`;
