import React from 'react';

import Container from 'components/shared/Container';
import TextCloud from './TextCloud';
import SectionHeader from 'components/shared/SectionHeader';

import { QuestionsRow, Column, Row } from './QuestionsSection.styled';

import shape1 from 'assets/icons/Shape1.svg';
import shape2 from 'assets/icons/Shape2.svg';
import shape3 from 'assets/icons/Shape3.svg';

const QuestionsSection = ({ title, clouds, illustration }) => {
   return (
      <section>
         <SectionHeader sectionText={title} />
         <Container as="section">
            <QuestionsRow>
               <Column className="left">
                  <Row>
                     <TextCloud
                        image={shape1}
                        cloud={clouds[0]}
                        className="firstImage"
                     />
                     <img
                        className="person-illustration"
                        src={illustration}
                        alt=""
                     />
                  </Row>

                  <Row className="justify-end">
                     <TextCloud
                        image={shape2}
                        cloud={clouds[1]}
                        className="secondImage"
                     />
                  </Row>
               </Column>
               <Column className="right">
                  <TextCloud
                     image={shape3}
                     cloud={clouds[2]}
                     className="thirdImage"
                  />
               </Column>
            </QuestionsRow>
         </Container>
      </section>
   );
};

export default QuestionsSection;
