import styled from 'styled-components';
import { colors, breakpoints } from 'variables';

export const CardContainer = styled.article`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   text-align: center;
   font-weight: 400;
   color: ${colors.black};
   margin-top: 60px;

   ${breakpoints.lg`
      flex-basis: 350px;
      margin: 0 15px;
      display: flex;
      &:first-of-type {
         margin-left: 0;
      }

      &:last-of-type {
         margin-right: 0;
      }
   `}
`;

export const ImageContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   ${breakpoints.lg`
      height: 460px;
   `}
`;

export const ImageBg = styled.div`
   flex-grow: 2;
   background-color: ${(props) => props.backgroundColor};
   border-radius: 12px;
   display: flex;
   height: 270px;
   align-items: flex-end;
   margin: 0 15px;
   justify-content: ${({ imagePosition }) =>
      imagePosition === 'right' ? 'flex-end' : 'center'};
   ${breakpoints.md`
      margin: 0;
      max-width: 350px;
      height: ${({ featured }) => (featured ? '100%' : '350px')};
   `}
`;

export const CardImage = styled.img`
   border-radius: 12px;
   max-height: 100%;
   display: block;
`;

export const CardDescription = styled.p`
   margin: 0;
   flex-grow: 3;
   font-size: 1.6rem;
   line-height: 26px;
`;

export const CardHeader = styled.h2`
   margin: 15px 0 10px;
   flex-grow: 1;
   line-height: 28px;
   flex-grow: 1;
   strong {
      font-weight: 700;
   }

   ${breakpoints.lg`
      font-size: 2.2rem;
      margin: 40px 0 20px;
      line-height: 30px;
      strong { display: block }
   `}
`;

export const TextContainer = styled.section`
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   margin: 0 20px 33px;
`;
