import React from 'react';
import FeatureCard from './FeatureCard';
import { StyledContainer } from './FeatureSection.styled';

const FeatureSection = ({ cards }) => {
   const renderCards = cards.map((card, index) => (
      <FeatureCard
         color={card.color}
         image={card.image}
         imageX2={card.imageX2}
         imagePosition={card.imagePosition}
         headerText={card.headerText}
         description={card.description}
         featured={index === 1 && true}
         buttonLink={card.buttonLink}
         key={index}
      />
   ));

   return (
      <StyledContainer id="featured" as="section">
         {renderCards}
      </StyledContainer>
   );
};

export default FeatureSection;
