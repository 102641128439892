import SectionHeader from 'components/shared/SectionHeader';
import React from 'react';
import CapabilitiesCard from './CapabilitiesCard';
import { StyledContainer } from './CapabilitiesSection.styled';

const CapabilitiesSection = ({ cards }) => {
   const sectionText = ['Jakie mamy na to ', <strong>rozwiązanie?</strong>];

   const cardsList = cards.map((card, index) => (
      <CapabilitiesCard
         cardHeight={card.height}
         cardImgAlignment={card.imgAlignment}
         key={index}
         image={card.image}
         imageX2={card.imageX2}
         buttonText={card.buttonText}
         buttonTo={card.buttonTo}
         headerText={card.headerText}
         description={card.description}
         reverseRow={card.reverseRow}
         sectionId={card.sectionId}
         color={card.color}
      />
   ));

   return (
      <section>
         <SectionHeader sectionText={sectionText} />
         <StyledContainer as="section">{cardsList}</StyledContainer>
      </section>
   );
};

export default CapabilitiesSection;
