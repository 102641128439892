import styled from 'styled-components';
import { breakpoints } from 'variables';

export const QuestionsRow = styled.div`
   margin-top: 70px;
   width: 100%;
   display: block;

   ${breakpoints.lg`
      justify-content: space-between;
      display: flex;
      flex-direction: row;
   `};
`;

export const Column = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;

   ${breakpoints.lg`
      align-items: normal;
   `}
`;

export const Row = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   .person-illustration {
      height: 270px;
      ${breakpoints.lg`
         height: 100%;
      `}
   }

   &.justify-end {
      justify-content: flex-end;
   }

   ${breakpoints.lg`
      flex-direction: row;

      &.justify-end {
         div {
            margin-top: 40px;
            margin-right: 80px;
         }
      }

      > img {
         margin-left: 30px;
      }
   `};
`;
