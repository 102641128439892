import React from 'react';
import SectionHeader from 'components/shared/SectionHeader';
import PackagesList from './PackagesList';
import routes from 'data/globals/routes';

const PackagesSection = () => {
   const sectionText = [<strong>Wybierz pakiet</strong>, ' dla siebie'];

   const packagesLinks = {
      link: {
         to: routes.pricing,
         text: 'Porównaj wszystkie pakiety',
      },
      buttons: [
         {
            text: 'Dowiedz się więcej',
            to: routes.pricing,
            state: { sectionId: 'pakiety' },
         },
         {
            text: 'Dowiedz się więcej',
            to: routes.pricing,
            state: { sectionId: 'pakiety' },
         },
         {
            text: 'Dowiedz się więcej',
            to: routes.pricing,
            state: { sectionId: 'pakiety' },
         },
      ],
   };

   return (
      <section>
         <SectionHeader sectionText={sectionText} />

         <PackagesList packagesLinks={packagesLinks} />
      </section>
   );
};

export default PackagesSection;
