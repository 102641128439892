import Button from 'components/shared/Button';
import React, { Fragment } from 'react';
import {
   CardContainer,
   CardDescription,
   CardHeader,
   CardImage,
   ImageBg,
   ImageContainer,
   TextContainer,
} from './FeatureCard.styled';

const FeatureCard = ({
   color,
   image,
   imageX2,
   headerText,
   description,
   buttonLink,
   featured,
   imagePosition,
}) => {
   return (
      <CardContainer>
         <ImageContainer>
            <ImageBg
               backgroundColor={color}
               featured={featured}
               imagePosition={imagePosition}
            >
               <CardImage src={image} srcSet={`${image} 1x, ${imageX2} 2x`} />
            </ImageBg>
         </ImageContainer>

         <TextContainer>
            <CardHeader>
               {headerText.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </CardHeader>
            <CardDescription>{description}</CardDescription>
         </TextContainer>
         {buttonLink && (
            <Button small link={buttonLink}>
               Czytaj Dalej
            </Button>
         )}
      </CardContainer>
   );
};

export default FeatureCard;
