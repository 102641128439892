import arrow_right from 'assets/icons/arrow_right_24.svg';
import ArrowIndicator from 'components/shared/ArrowIndicator';
import SectionHeader from 'components/shared/SectionHeader';
import routes from 'data/globals/routes';
import React, { useState } from 'react';
import { Fragment } from 'react';
import {
   MoreInformation,
   PreviewContainer,
   PreviewDescription,
   StyledContainer,
   StyledLink,
   TabList,
   TabListItem,
   TilesContainer,
} from './FunctionalitiesSection.styled';

const FunctionalitiesSection = ({ title, tabs }) => {
   const [activeTab, setActiveTab] = useState(0);

   return (
      <section>
         <SectionHeader sectionText={title} />

         <StyledContainer as="section">
            <ArrowIndicator>Przeglądaj możliwości systemu</ArrowIndicator>

            <TilesContainer>
               <TabList role="tablist">
                  {tabs.map((tab, idx) => {
                     return (
                        <TabListItem
                           role="tab"
                           tabIndex={0}
                           key={idx}
                           onClick={() => setActiveTab(idx)}
                           className={idx === activeTab ? 'active' : ''}
                           aria-selected={idx === activeTab}
                        >
                           {tab.title}
                        </TabListItem>
                     );
                  })}
               </TabList>

               <PreviewContainer>
                  <PreviewDescription>
                     {tabs[activeTab].text.mixed ? (
                        tabs[activeTab].text.mixed.map((text, idx) => {
                           return <Fragment key={idx}>{text}</Fragment>;
                        })
                     ) : (
                        <>
                           <strong>{tabs[activeTab].text.bold}</strong>{' '}
                           {tabs[activeTab].text.normal}
                        </>
                     )}
                  </PreviewDescription>

                  <img
                     src={tabs[activeTab].image}
                     srcSet={`${tabs[activeTab].image} 1x, ${tabs[activeTab].imageX2} 2x`}
                     alt=""
                  />
               </PreviewContainer>
            </TilesContainer>
            <MoreInformation>
               <StyledLink to={routes.functionalities}>
                  {' '}
                  Zobacz wszystkie funkcjonalności
                  <img src={arrow_right} alt="arrow" />
               </StyledLink>
            </MoreInformation>
         </StyledContainer>
      </section>
   );
};

export default FunctionalitiesSection;
