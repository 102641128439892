import styled from 'styled-components';
import Container from 'components/shared/Container';
import { breakpoints } from 'variables';

export const StyledContainer = styled(Container)`
   justify-content: space-between;
   flex-direction: column;
   ${breakpoints.lg`
      margin-top: 120px;
      flex-direction: row;
   `}
`;
