import styled from 'styled-components';
import Container from 'components/shared/Container';
import { colors, transitions, breakpoints } from 'variables';
import { Link } from 'gatsby';

export const StyledContainer = styled(Container)`
   flex-direction: column;
   align-items: center;
`;

export const TilesContainer = styled.div`
   margin-top: 20px;
   width: 100%;

   ${breakpoints.md`
      margin-top: 50px;
   `}
`;

export const TabList = styled.div`
   display: flex;
   justify-content: center;
`;

export const TabListItem = styled.button`
   :first-of-type {
      border-top-left-radius: 6px;
      border-left: 1px solid ${colors.brand};
      border-bottom-left-radius: 6px;
   }
   :last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
   }
   font-size: 1.3rem;
   font-weight: 500;
   line-height: 16px;
   position: relative;
   text-align: center;
   padding: 20px 8px 19px;
   color: ${colors.black};
   background: ${colors.white};
   border: 1px solid ${colors.brand};
   border-left: none;
   cursor: pointer;
   transition: background ${transitions.easeInOut},
      color ${transitions.easeInOut};
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;

   :hover {
      background: ${colors.brand};
      color: ${colors.white};
   }

   &:after {
      content: '';
      position: absolute;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 15px solid ${colors.brand};
      bottom: -15px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      opacity: 0;
      transition: opacity ${transitions.easeInOut};
   }

   &.active {
      color: ${colors.white};
      background-color: ${colors.brand};
      &:after {
         opacity: 1;
      }
   }

   &:nth-of-type(n + 4) {
      display: none;
   }

   ${breakpoints.md`
      &:nth-of-type(n+4) {
         display: list-item;
      }
      &:nth-of-type(n+5) {
         display: none;
      }
   `}
   ${breakpoints.lg`
      &:nth-of-type(n+5) {
         display: list-item;
      }
   `}
`;

export const PreviewContainer = styled.article`
   margin-top: 55px;

   img {
      width: 100%;
   }
`;

export const PreviewDescription = styled.p`
   line-height: 28px;

   ${breakpoints.lg`
      padding: 0 90px;
   `}
`;

export const MoreInformation = styled.p`
   font-size: 1.5rem;
   margin-top: 50px;

   img {
      margin-left: 10px;
   }

   ${breakpoints.lg`
      font-size: 1.8rem;
   `}
`;

export const StyledLink = styled(Link)`
   font-weight: 600;
   :hover {
      color: ${colors.brand};
   }
   display: flex;
   align-items: center;

   img {
      transform: ${({ $rotateArrow }) =>
         $rotateArrow ? 'rotate(90deg)' : null};
   }
`;
