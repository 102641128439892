import Button from 'components/shared/Button';
import routes from 'data/globals/routes';
import React, { Fragment } from 'react';
import {
   Description,
   DescriptionContainer,
   Header,
   ImageContainer,
   Row,
} from './CapabilitiesCard.styled';

const CapabilitiesCard = ({
   image,
   buttonText,
   buttonTo,
   headerText,
   description,
   reverseRow = false,
   color,
   imageX2,
   cardHeight = 365,
   cardImgAlignment,
   sectionId,
}) => {
   return (
      <Row reverseRow={reverseRow} id={sectionId}>
         <ImageContainer
            cardImgAlignment={cardImgAlignment}
            cardHeight={cardHeight}
            reverseRow={reverseRow}
            backgroundColor={color}
         >
            <img
               src={image}
               srcSet={`${image} 1x, ${imageX2} 2x`}
               alt="a person"
            />
         </ImageContainer>

         <DescriptionContainer reverseRow={reverseRow}>
            <Header>
               {headerText.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </Header>

            <Description>{description}</Description>
            {buttonText && (
               <Button
                  small
                  link={routes.functionalities}
                  state={{ sectionId: buttonTo.section }}
               >
                  {buttonText}
               </Button>
            )}
         </DescriptionContainer>
      </Row>
   );
};

export default CapabilitiesCard;
