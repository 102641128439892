import React, { Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'variables';
import arrowRight from 'assets/icons/arrow_right_36.svg';
import scrollTo from 'utilities/scrollTo';

const Cloud = styled.a`
   cursor: pointer;
   width: 100%;
   max-width: 400px;
   position: relative;
   margin-top: 30px;

   .arrow {
      position: absolute;
      width: 36px;
      height: 36px;
      bottom: 40px;
      right: 50px;
   }

   img {
      position: relative;
      z-index: -1;
      width: 100%;
      max-width: 370px;
   }

   h2 {
      font-size: 1.8rem;
      top: 50%;
      left: 50%;
      position: absolute;
      font-weight: 300;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 50px;
      line-height: 1.4;

      strong {
         font-weight: 800;
      }
   }

   &.firstImage {
      h2 {
         transform: translate(-50%, calc(-50% - 10px));
      }
      .arrow {
         bottom: 30px;
      }
   }

   &.secondImage {
      h2 {
         margin-top: -20px;
         transform: translate(-50%, calc(-50% + 10px));
      }

      .secondImage-img {
         transform: rotate(180deg);
      }
   }

   &.thirdImage {
      justify-content: center;
      max-height: 350px;

      h2 {
         transform: translate(-50%, -70%);
      }
      .thirdImage-img {
         transform: translateY(-60px) rotate(-90deg) scale(0.73);
      }
      .arrow {
         bottom: 100px;
         right: 60px;
      }
   }

   ${breakpoints.xs`
   &.thirdImage {

      .thirdImage-img {
         transform: translateY(-90px) rotate(-90deg) scale(0.73);
      }

   }
   `}

   ${breakpoints.lg`
      margin-top: 0;

      h2 {
         padding: 0 70px;
         line-height: 32px;
         font-size: 2.2rem;
      }

      &.secondImage  {
         h2 { margin-top: 0px; }
         .secondImage-img { transform: rotate(0deg); }
         .arrow {
            bottom: 20px;
            right: 120px;
         }
      }

      &.thirdImage {
         display: flex;
         margin-top: 70px;
         max-height: initial;
         .thirdImage-img  {
            margin-top: 0;
            transform: rotate(0) scaleY(-1);
         }

         h2 {
            transform: translate(-50%, -50%);
            padding: 0 50px 0 70px;
         }
         .arrow {
            bottom: 20px;
            right: 90px;
         }
      }
   `}
`;

const TextCloud = ({ image, className, cloud }) => {
   return (
      <Cloud
         className={className}
         onClick={() => scrollTo(cloud.questionLink, 50)}
      >
         <img className={`${className}-img`} src={image} alt="cloud" />
         <h2>
            {cloud.question.map((text, idx) => (
               <Fragment key={idx}>{text}</Fragment>
            ))}
         </h2>
         <img className="arrow" src={arrowRight} alt="arrow" />
      </Cloud>
   );
};

export default TextCloud;
