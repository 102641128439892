import implImage from 'assets/images/photo15.png';
import Button from 'components/shared/Button';
import Container from 'components/shared/Container';
import routes from 'data/globals/routes';
import React, { Fragment } from 'react';
import {
   ImgContainer,
   ImplementationHeader,
   StyledSection,
} from './ImplementationSection.styled';

const ImplementationSection = ({ title }) => {
   return (
      <Container>
         <StyledSection>
            <ImgContainer>
               <img src={implImage} alt="implementation" />
            </ImgContainer>
            <ImplementationHeader>
               <h2>
                  {title.map((text, idx) => (
                     <Fragment key={idx}>{text}</Fragment>
                  ))}
               </h2>
               <Button filled link={routes.implementation}>
                  Poznaj historię produktu
               </Button>
            </ImplementationHeader>
         </StyledSection>
      </Container>
   );
};

export default ImplementationSection;
