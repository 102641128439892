import styled from 'styled-components';
import { breakpoints } from 'variables';

export const StyledSection = styled.section`
   margin-top: 50px;
   display: grid;
   justify-content: center;
   align-items: center;
   ${breakpoints.md`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const ImgContainer = styled.div`
   img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 430px;
   }
`;

export const ImplementationHeader = styled.header`
   padding: 30px;
   display: flex;
   flex-direction: column;
   a {
      align-self: flex-start;
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 25px auto;
      ${breakpoints.lg`
         padding-top: 20px;
         padding-bottom: 20px;
         margin: 50px auto;
         display: inline-block;
         `}
   }
   ${breakpoints.md`
         padding: 50px;
         margin: 50px auto;
        display: initial;
   `}
   h2 {
      text-align: center;
      line-height: 1.5;
      ${breakpoints.md`
         line-height: 55px;
         text-align: left;
      `}
      ${breakpoints.lg`
        text-align: left;
      `}
   }
`;
