import styled from 'styled-components';
import { breakpoints } from 'variables';

export const Row = styled.article`
   width: 100%;
   display: block;
   margin-top: 60px;
   :first-of-type {
      margin-top: 0px;
   }

   ${breakpoints.md`
      display: flex;
      margin-top: 90px;
      flex-direction: ${({ reverseRow }) =>
         reverseRow ? 'row-reverse' : 'row'};

      &:first-of-type {
         margin-top: 0;
      }
   `}
`;

export const ImageContainer = styled.div`
   border-radius: 12px;
   display: flex;
   justify-self: flex-start;
   background-color: ${(props) => props.backgroundColor};
   justify-content: center;
   img {
      align-self: ${({ cardImgAlignment = 'center' }) => cardImgAlignment};
      border-radius: 12px;
      max-width: 100%;
      height: auto;
   }
   ${breakpoints.md`
      height: ${({ cardHeight }) => cardHeight}px;
      flex: 50%;
      ${({ reverseRow }) =>
         reverseRow ? 'padding-left: 15px' : 'padding-right: 15px'};
   `}
`;

export const DescriptionContainer = styled.section`
   align-self: center;
   margin: 15px 15px 0;
   display: block;
   text-align: center;

   > button {
      margin: 0 auto;
   }

   ${breakpoints.md`
      flex: 50%;
      text-align: left;
      margin: 0;
      ${({ reverseRow }) =>
         reverseRow ? 'padding-right: 130px' : 'padding: 0 75px 0 60px'};

      > button {
         margin: initial;
      }
   `}
`;

export const Header = styled.h2`
   font-size: 2rem;
   font-weight: 300;
   line-height: 28px;

   strong {
      font-weight: 600;
   }

   ${breakpoints.lg`
      font-size: 3rem;
      line-height: 36px;
   `}
`;

export const Description = styled.p`
   line-height: 24px;
   margin: 10px 0 15px;
   font-weight: 300;

   ${breakpoints.lg`
      margin: 30px 0 20px;
      line-height: 28px;
   `}
`;
